.practice__parrent {
  background: no-repeat left center / cover;
  height: 100vh;
  width: 100%;
  position: fixed;
  overflow-y: auto;
  background-blend-mode: screen;
  background-color: #435a36;
}

.practice__container {
  padding: 125px 0 25px;
}
.practice__content {
  width: 680px;
  margin: auto;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  text-align: center;
  padding: 2rem 0 10px;
}

.practice__header {
  background: #ffffff80 0% 0% no-repeat padding-box !important;
  width: 260px;
  padding: 3rem;
  margin: auto;
  border-radius: 65px;
  -webkit-border-radius: 65px;
  -moz-border-radius: 65px;
  -ms-border-radius: 65px;
  -o-border-radius: 65px;
}

.practice__header-logo {
  width: 90%;
  margin: auto;
}
.practice__header-logo img {
  width: 100%;
}
.practice__header-title {
  color: #ae0f0b;
  margin-top: 0;
  font-weight: bold;
}
.practice__main-row {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem 0;
  justify-content: space-between;
  width: 100%;
  margin: 2rem auto;
}
.practice__main-row > div {
  width: 43%;
}
/* .practice__main-row > div:last-child {
  margin: 0 auto;
} */

.practice__main-col {
  position: relative;
  cursor: pointer;
  height: 90px;
  width: 100%;
  background: #ffffff80 0% 0% no-repeat padding-box !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
}
.practice__main-col-content {
  display: flex;
  align-items: center;
  padding-left: 5px;
  height: 100%;
  width: 100%;
}

.practice__main-box {
  font-size: 1.2rem;
}

.main-box__icon {
  width: 60px;
  display: flex;
  align-items: center;
}
.main-box__icon img {
  width: 100%;
  height: 100%;
}

.main-box__maori-title {
  font-weight: bold;
}

.main-box__eng-title {
  margin-left: 10px;
  font-size: 1rem;
  margin-top: 3px;
}

@media only screen and (max-width: 768px) {
  .practice__content {
    width: 100%;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }
  .practice__main-col-content {
    width: auto;
    padding-left: 0;
    position: absolute;
    left: 30%;
  }
  .practice__main-row {
    justify-content: center;
  }
  .practice__main-row > div {
    width: 70%;
  }
  .practice__main-col {
    width: 100%;
    margin: 0 auto;
  }
  .practice__main-box {
    font-size: 1rem;
  }
}
@media only screen and (max-width: 580px) {
  .practice__header {
    background: #ffffff80 0% 0% no-repeat padding-box !important;
    width: 200px;
    padding: 1rem;
    margin: auto;
    border-radius: 65px;
    -webkit-border-radius: 65px;
    -moz-border-radius: 65px;
    -ms-border-radius: 65px;
    -o-border-radius: 65px;
}
.practice__header-title {
  color: #ae0f0b;
  margin-top: 0;
  font-size: 1rem;
  font-weight: bold;
}
  .practice__main-col-content {
    left: 5%;
  }
}