body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #435A36;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: SemiBoldFont;
  src: url("./fonts/Poppins-SemiBold.ttf");
}
@font-face {
  font-family: MediumFont;
  src: url("./fonts/Poppins-Medium.ttf");
}
@font-face {
  font-family: RegularFont;
  src: url("./fonts/Poppins-Regular.ttf");
}

h1 {
  font-family: SemiBoldFont;
  color: #2d373c;
}

h2 {
  font-family: SemiBoldFont;
  color: #2d373c;
}

h3 {
  font-family: SemiBoldFont;
  color: #2d373c;
}

p {
  color: #2d373c;
  font-family: RegularFont;
  font-size: 14px;

  @media only screen and (min-width: 768px) {
    font-size: 20px;
  }
}

a {
  color: #22170F;
  text-decoration: none;
}

.error-message {
  color: #5c9021;
  font-size: 10px;
  margin-top: -5px;
  text-align: left;
}
