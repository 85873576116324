@keyframes alert-animate {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}
/* @keyframes delete-alert-animate {
  0% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
} */

.question__box {
  position: relative;
  background-color: rgba(255, 255, 255, 0.65);
  width: 50%;
  margin: 0 auto 10rem auto;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  text-align: center;
  padding-top: 2rem;
}
.question__box-header {
  width: 90%;
  margin: 0 auto;
}
.question__box-title {
  padding: 1rem 0;
  border-radius: 5px;
  background-color: #fff;
  color: #ae0e0a;
  font-weight: bolder;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.question__box-text {
  font-weight: bolder;
  font-size: 1.5rem;
}
.question__box-main {
  position: relative;
  margin-top: 2rem;
}
.question__box-row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px 0;
  width: 90%;
  margin: 1.3rem auto;
  justify-content: space-between;
  align-items: center;
}
.question__box-row-child {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.question__box-col {
  background-color: transparent;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  margin-top: 1.2rem;
  width: 90%;
  font-weight: bolder;
  overflow: hidden;
}
.question__box-col span {
  cursor: pointer;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  padding: 25px 15px;
  font-size: 1.1rem;
}
.question__box-number {
  margin: 2rem 0 1rem 0;
  font-weight: bold;
  font-size: 1.5rem;
  padding-bottom: 2rem;
}

.alert {
  position: sticky;
  left: 50%;
  bottom: 0;
  animation-name: alert-animate;
  animation-duration: 0.6s;
  width: 100%;
  margin-top: -4.5rem;
  background-color: #232d29;
  display: block;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
}
/* .delete-alert {
  position: sticky;
  left: 50%;
  bottom: 0;
  width: 100%;
  margin-top: -4.5rem;
  animation-name: delete-alert-animate;
  animation-duration: 1.5s;
  transition: 2s;
  background-color: #232d29;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  -webkit-transition: 2s;
  -moz-transition: 2s;
  -ms-transition: 2s;
  -o-transition: 2s;
} */
.alert__content {
  padding: 1rem 0;
}
.alert__content span {
  color: #fff;
  display: block;
}
.alert__content-text {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 1rem;
}
.alert__content-score {
  font-size: 25px;
}
.alert__content-button {
  width: 30%;
  border-radius: 12px;
  padding: 1rem 0;
  font-size: 1.2rem;
  cursor: pointer;
  margin: 1rem auto 0 auto;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

/* Helper Classes */

.red__option {
  background-color: #b20d30;
  color: #fff;
}
.green__option {
  color: #fff;
}
.green__option-1 {
  background-color: #537044;
}
.green__option-2 {
  background-color: #3ddb96;
}
.green__option-3 {
  background-color: #25b67e;
}
.green__option-4 {
  background-color: #288e28;
}
.green__option-5 {
  background-color: #0c6e3d;
}
.orange__option {
  background-color: #de7725 !important;
  color: #fff !important;
}
.white__option {
  background-color: #fff;
  color: #000;
}
.disable__click {
  pointer-events: none;
}
.error-btn {
  background-color: #b20d30;
}
.success-btn {
  background-color: #4d9e53;
}

/* Responsive */

@media only screen and (max-width: 970px) {
  .question__box {
    width: 70%;
  }
}
@media only screen and (max-width: 670px) {
  .question__box {
    width: 80%;
  }
}
@media only screen and (max-width: 590px) {
  .question__box {
    width: 100%;
  }
  .question__box-row {
    flex-wrap: wrap;
    margin: 0 auto;
  }
  .question__box-col {
    width: 90%;
    margin-bottom: 1.5rem;
  }
  .question__box-col span {
    font-size: 15px;
  }
  .question__box-number {
    margin-top: 0;
  }
}

@media only screen and (max-width: 425px) {
  .question__box {
    width: 100%;
  }
  .question__box-header {
    width: 70%;
  }
  .question__box-header .question__box-title {
    font-size: 0.9rem;
  }
  .question__box-header .question__box-text {
    font-size: 1rem;
  }
  .question__box-row {
    flex-wrap: wrap;
    margin: 0 auto;
  }
  .question__box-col {
    margin-bottom: 1.5rem;
  }
  .question__box-col span {
    font-size: 13px;
  }
  .question__box-number {
    margin-top: 0;
  }
}
